.children {
    background-color: #555555;
    opacity: 0;
    max-height: 0;
    overflow: hidden;
    transition: 
        opacity 0.5s,
        max-height 0.5s;
}

.children.is-expanded {
    opacity: 1;
    max-height: 80vh;
    overflow-y: scroll;
}

.project-tile {
    background-color: #666666;
    padding: 0;
    margin: 20px;
    transition: 
        max-height 0.5s;
}

.project-tile.is-expanded {
    max-height: 90vh;
}

.project-icon {
    min-width: 100%;
    max-height: 5vh;
    object-fit: contain;
    transition: 
        background-color 0.5s;
}

.project-tile-header {
    padding: 20px;
}

.project-tile-header:hover {
    background-color: #888888;
}